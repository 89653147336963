import React from 'react'
import Layout from '../layouts'
import LinkTop from '../components/LinkTop'

const Thanks = () => (
  <Layout>
    <h1>お問い合わせありがとうございました！</h1>
    <p>お仕事のお問い合わせについては順次返信いたします。</p>
    <LinkTop scrollStepInPx="50" delayInMs="16.66" />
  </Layout>
)

export default Thanks
